import * as React from "react";
import Helmet from "react-helmet";
import { A3, A4, A7 } from "../components/fonts";
import Container from "../components/container";
import PropTypes from "prop-types";

const VimeoVideo = (props) => {
  return (
    <div className="relative pt-vimeo2">
      <iframe
        className="absolute rounded-md shadow-md overflow-hidden top-0 left-0 w-full h-full"
        src={`//player.vimeo.com/video/${props.identifier}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;color=ff5900&amp;background=1`}
        frameBorder="0"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowFullScreen=""
      ></iframe>
    </div>
  );
};

VimeoVideo.propTypes = {
  identifier: PropTypes.string,
};

const IntroPage = () => {
  return (
    <>
      <Helmet title="Home" />
      <Container className="bg-wbSky">
        <div className="col-span-30 mt-32">
          <A3 className="text-center pb-16">
            An introduction
            <br /> to Waybridge.
          </A3>
          <div className="px-6 w-full md:w-3/4 mx-auto">
            <VimeoVideo identifier="662374994" />
          </div>
          <A4 className="text-center pt-16 pb-12 max-w-xl mx-auto">
            We&apos;d love to show you how Waybridge can impact your operation.
          </A4>
          <A7 className="text-center pb-16">
            Send us an email at EMAIL or schedule time to meet below.
          </A7>
        </div>
      </Container>
    </>
  );
};
export default IntroPage;
